
import React, { useState, useRef ,useEffect, useCallback} from "react";
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import CartIcon from "../components/cart/cart-icon-right"
import {Table,Image,Button} from "react-bootstrap"
import Link from "gatsby-link";
import img from "../images/blog.jpg"
import removeImg from "../images/remove_icon.svg"
import { Helmet } from 'react-helmet';
import GET_CART from "../queries/get-cart";
import UPDATE_CART from "../mutations/update-cart";
import REMOVE_PRODUCT from "../mutations/remove-product";
import { useQuery, useMutation } from "@apollo/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../components/loading/loading"
import {isTokenValid,getLocalLang, authToLogin,isSpecial as isSpecialFunc,isUserLoggedIn} from "../utils/functions"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby"
import { wordpressUrl,defaultLang } from "../consts";
//const lang = getLocalLang();
const baseCheckUrl = `${wordpressUrl}/wp-json/checkout/login`
const Cart = () => {
  useEffect(() => {
    authToLogin()
  }, []); 
  const isSpecial = isSpecialFunc();
  const {t} = useTranslation();
  const notify = () => toast.success(t('itemRemoved'));
  const [cartProduct, setCartProduct] = useState([]);
  const [totalPrice, setTotalPrice] = useState(null);
  const [totalQty, setTotalQty] = useState(null);
  const [auth, setAuth] = useState(isUserLoggedIn());
  const [lang, setLang] = useState(getLocalLang());
  console.log(lang)
  const [productFields, setProductFields] = useState({
    key: "",
    quantity: ""
  });
  //add to cart mutation
  const [ changeCartQty ] = useMutation(UPDATE_CART, {
    variables: productFields,
    onCompleted: () => {
      console.log("success")
    },
    onError: (error) => {
      toast.error(error)
    },
    refetchQueries: [{ query: GET_CART }],

  });
  function debounce(fn, ms) {
    let timer;
    return function(...args) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        if(typeof fn !== "undefined"){
          fn(...args)
          timer = null;
        }
      }, ms);
    }
  }
  const debounceFn = debounce(changeCartQty,700)  
  useEffect(() => {
    debounceFn()
  }, [productFields.quantity])
  const [key, setProductkey] = useState(null);
  useEffect(() => {
    removeProductFromCart()
  }, [key])


//get cart data
const { data, refetch } = useQuery(GET_CART, {
  fetchPolicy:"cache-and-network",
  onCompleted: () => {
    let cartData = data.cart.contents.edges
    setCartProduct(cartData)
    setTotalPrice(data.cart.subtotal)
    setTotalQty(data.cart.contents.itemCount)
  },
  onError: (error) => {
    toast.error(error)
  },
  
});

  //add to cart fun
  const addToCartNum = (key,e) =>{
    setProductFields({
      key: key,
      quantity: Number(e.target.value)
    }
    )
  }
  
  //remove product function
  const removeProduct = (key) =>{
    Loading.show()
    setProductkey(key)
  }

  const [ removeProductFromCart ] = useMutation(REMOVE_PRODUCT, {
    variables: {
      input: {keys: key},
    },
    onCompleted: () => {
      Loading.remove()
      notify()
    },
    //onCompleted: refetch,
    onError: (error) => {
      toast.error(error)
    },
    refetchQueries: [GET_CART, 'GetCarts'],
  });
  const formEl = useRef(null);
  const goToCheckout = () =>{
    isTokenValid()
    const lang = getLocalLang()
    formEl.current.setAttribute('action', `${baseCheckUrl}${(lang === defaultLang) ? '' : '?lang=' + lang}`)
    formEl.current.submit()
  }

  return(
    <>
    <HeardTopWrapper isSpecial={isSpecial} title={t('yourCartPage')}/>
    <Layout >
      <Helmet>
          <title>{t('myKitchenInspector')}｜{t('Cart')}</title>
      </Helmet>
      <PageContainer>
        <div className="topBreadscrum">
          { !isSpecial && <div className="topBreadscrumLeft"><Link to="/shop" className="top-nav">{t('productList')}</Link> &gt; {t('Cart')}</div>}
          
          <CartIcon totalQty={totalQty}/>
        </div>
        <form action={`${baseCheckUrl}${(lang === defaultLang) ? '' : '?lang=' + lang}`} method="post" ref={formEl}>
          <input type="hidden" name="Authorization" value={auth}/>
          {/* <button type="submit">submit</button> */}
        </form>
        {
          cartProduct.length !== 0 ?
          <div>
          <Table responsive="md" className="table-custom">
            <thead>
              <tr>
                <th>{t('products')}</th>
                <th>{t('price')}</th>
                <th>{t('quantity')}</th>
                <th>{t('total')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            { 
              cartProduct.map((item, index) => {
                return <tr key={`item_${index}`}>
                <td>
                  <div className="product-info">
                    <div className="product-info-img">
                      <Image src={item.node.product.node.image ? item.node.product.node.image.link : img}/>
                    </div>
                    <div>
                      <h5>{item.node.product.node.name}</h5>
                    </div>
                  </div>
                </td>
                <td>{item.node.product.node.price || 0}</td>
                <td><input type="number" className="form-control" min="1" defaultValue={item.node.quantity} onChange={addToCartNum.bind(this,item.node.key)}/></td>
                <td>
                {item.node.total}
                </td>
                <td>
                  <Image src={removeImg} onClick={removeProduct.bind(this,item.node.key)} className="removeProduct"/>
                </td>
              </tr>
              })
            }
            </tbody>
          </Table>
          <div className="cart-totals-warp">
            <div className="cart-totals">
              <h3> {t('cartTotals')}</h3>
              <div className="flex">
                <div>{t('subTotal')}</div>
                <h1>{totalPrice}</h1>
              </div>
              <div className="text-right">
              <Button className="addButton" onClick={goToCheckout}>{t('checkout')}</Button>
              </div>
            </div>
          </div>
          </div>
          :<div className="text-center">
              <h2>{t('noItemsInTheCart')}</h2>
              <Link to="/shop">
                <Button className="addButton">
                  {t('addNewProducts')}
                </Button>
              </Link>
          </div>
        }
        
      
        
      </PageContainer>
      <ToastContainer />
    </Layout>
    </>

  )
  
}

export default Cart
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;